import { useEffect } from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'

import FlexSection from '@components/FlexSection'
import Gallery from '@components/Gallery'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_Gallery,
  WpPrograma_Acfpagefields_Content_Gallery
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_Gallery
  | WpPrograma_Acfpagefields_Content_Gallery

const FlexGallery = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, images, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom
      paddingTop={title ? true : false}
      tabs={tabs}
    >
      <div>
        {text && (
          <div
            tw="prose mx-auto mb-12 text-center!"
            css={[sectionColors.text === 'light' && tw`text-white!`]}
          >
            {text && parse(text)}
          </div>
        )}
        <Gallery images={images} />
      </div>
    </FlexSection>
  )
}

export default FlexGallery
