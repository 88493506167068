import tw, { styled, theme } from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
// import Swiper core and required modules
import SwiperCore, { Grid, Pagination, A11y } from 'swiper'

// install Swiper modules
SwiperCore.use([Grid, Pagination, A11y])

import { SRLWrapper } from 'simple-react-lightbox'
import { WpMediaItem } from '@_types/graphql-types'

const Gallery = ({ images }: { images: WpMediaItem[] }) => {
  return (
    <SRLWrapper
      options={{
        buttons: {
          showAutoplayButton: false,
          showDownloadButton: false
        }
      }}
    >
      <GalleryWrapper>
        <Swiper
          slidesPerView={2.25}
          grid={{
            rows: images.length < 5 ? 1 : 2
          }}
          a11y={{
            prevSlideMessage: 'Anterior',
            nextSlideMessage: 'Próximo',
            firstSlideMessage: 'Primeiro',
            lastSlideMessage: 'Último',
            paginationBulletMessage:
              'Foto {currentSlideIndex} de {totalSlides}',
            containerMessage: 'Galeria de fotos'
          }}
          spaceBetween={12}
          pagination={{
            clickable: true
          }}
          breakpoints={{
            1024: {
              slidesPerView: images.length < 7 ? 3 : 4,
              spaceBetween: 30
            }
          }}
          tw="w-full h-full"
        >
          {images.map(({ altText, localFile }, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  height: 'calc((100% - 30px) / 2)'
                }}
              >
                <a
                  tw="block w-full h-full"
                  href={localFile.childImageSharp['large'].src}
                  title={altText ? altText : 'Foto da galeria'}
                >
                  <div tw="w-full h-full overflow-hidden rounded-tl-[48px] rounded-br-[48px]">
                    <GatsbyImage
                      image={localFile.childImageSharp['large']}
                      alt={altText}
                      tw="w-full h-full cursor-pointer"
                      objectFit="cover"
                    />
                  </div>
                </a>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </GalleryWrapper>
    </SRLWrapper>
  )
}

const GalleryWrapper = styled.div`
  ${tw`h-96`}

  @media (min-width: ${theme`screens.lg`}) {
    ${tw`h-[680px]`}
  }

  .swiper {
    ${tw`pb-16`}
  }

  .swiper-pagination {
    ${tw`!bottom-0 z-10`}

    .swiper-pagination-bullet {
      ${tw`w-7 rounded-md h-1 bg-gray-500!`}

      &-active {
        ${tw`bg-lemann-dark-orange!`}
      }
    }
  }
`

export default Gallery
